// @flow

import type {Action} from '../../types';

export function updateAddress(payload: Object): Action {
  return {
    type: 'UPDATE_ADDRESS',
    payload: payload
  };
}

export function verifyAddressSuccess(formName: string, state: string): Action {
  return {
    type: 'VERIFY_ADDRESS_SUCCESS',
    payload: {
      formName,
      state
    }
  };
}

export function calcLetterPrice(extraService: string) {
  return {
    type: 'CALC_LETTER_PRICE',
    payload: {
      extraService
    }
  };
}

export function calcLetterPriceSuccess(totalPrice: number, providerAddrId: ?string | ?number, csFileURL: ?string): Action {
  return {
    type: 'CALC_LETTER_PRICE_SUCCESS',
    payload: {
      totalPrice,
      providerAddrId,
      csFileURL
    }
  };
}

export function submitLetter(payProvider: 'stripe' | 'paypal', name: string, orderId: ?string, email: ?string): Action {
  return {
    type: 'SUBMIT_LETTER',
    payload: {
      name,
      orderId,
      payProvider,
      email
    }
  };
}

export function submitLetterSuccess(): Action {
  return {
    type: 'SUBMIT_LETTER_SUCCESS',
    payload: {}
  };
}
