// @flow

import { Col, Result, Row } from "antd"

import HtmlHead from "../components/HtmlHead"
import Link from "../components/Link"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { isSendingLetter } from "../redux/selectors/lettersSelectors"
import { submitLetterSuccess } from "../redux/actions/lettersActions"

const SuccessView = ({ location }: Object) => {
  const dispatch = useDispatch()
  const fromCheckout = useSelector(isSendingLetter)

  useEffect(() => {
    if (fromCheckout) {
      dispatch(submitLetterSuccess())
    }
  }, [])

  const { state } = location
  const { id, email, expected_delivery_date } = state || {}

  const expectMsg = expected_delivery_date ? (
    `It is expected to be delivered on ${expected_delivery_date}.`
  ) : (
    <>
      It may take up to 2 weeks for the letter to be delivered;{" "}
      <Link to="/faq?question=time-to-deliver">
        times vary based on the destination address
      </Link>
      .
    </>
  )
  const confirmEmailMsg = email
    ? `Confirmation email will be shortly sent to ${email}.`
    : ""

  return (
    <>
      <HtmlHead title="Success | PostSeal" meta={{ robots: "noindex" }} />
      <Result
        status="success"
        title="Success!"
        subTitle={
          <Row>
            <Col xs={24} md={{ span: 12, offset: 6 }}>
              <p>
                Letter {id} has been submitted. {confirmEmailMsg} {expectMsg}
              </p>
              <p>
                If you have any questions about your letter, please email us at
                info@postseal.com.
              </p>
            </Col>
          </Row>
        }
        extra={[
          <div key="result" className="top-space">
            <Link
              className="ant-btn ant-btn-lg ant-btn-round ant-btn-primary"
              to="/"
              key="home"
            >
              Home
            </Link>
            <Link
              style={{ marginLeft: "16px" }}
              className="ant-btn ant-btn-lg ant-btn-round"
              to="/send-letter"
              key="submitLetter"
            >
              Send a letter
            </Link>
          </div>,
        ]}
      />
    </>
  )
}

export default SuccessView
