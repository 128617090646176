// @flow

export const DEFAULT_CCY = 'GBP';

// in pixels
export const A4Width = 595;
export const A4Height = 842;
export const USLetterWidth = 612;
export const USLetterHeight = 792;

// 5MB in binary
export const FILE_SIZE_BYTE_LIMIT = 5242880;

export const AMERICAN_COUNTRIES = ['US', 'AS', 'PR', 'FM', 'GU', 'MH', 'MP', 'PW', 'VI', 'UM'];
