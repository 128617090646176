// @flow

import type {AddressState, State} from '../../types';

import {AMERICAN_COUNTRIES} from '../../constants';
import {getLettersState} from './index';

export function isSendingLetter(state: State): boolean {
  return getLettersState(state).isSendingLetter;
}

export function getReturnAddress(state: State): ?AddressState {
  return getLettersState(state).returnAddress;
}

export function isCreatingReturnAddress(state: State): boolean {
  return getLettersState(state).isCreatingReturnAddress;
}

export function getDestinationAddress(state: State): ?AddressState {
  return getLettersState(state).destinationAddress;
}

export function isCalcLetterPrice(state: State): boolean {
  return getLettersState(state).isCalcLetterPrice;
}

export function getTotalPrice(state: State): ?number {
  return getLettersState(state).totalPrice;
}

export function isLetterUSOnly(state: State): boolean {
  const returnAddr = getReturnAddress(state);
  const destinationAddr = getDestinationAddress(state);
  return !!returnAddr && !!destinationAddr && AMERICAN_COUNTRIES.indexOf(returnAddr.country) >= 0 && AMERICAN_COUNTRIES.indexOf(destinationAddr.country) >= 0;
}

export function getExtraService(state: State): string {
  return getLettersState(state).extraService;
}
